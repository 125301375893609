import { combineReducers } from 'redux';
import { REQUEST, RECEIVE, FAIL } from './action-types';

const testy = (state = {}, action) => {
    switch(action.type) {
        case REQUEST:
            console.log('REDUCER REQ');
            return {
                ...state,
                data: [],
                error: false,
                loading: true,
                loaded: false,
            };
        case RECEIVE:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
                loaded: true,
            };
        case FAIL:
            console.log('REDUCER FAIl');

            return {
                ...state,
                data: [],
                error: true,
                loading: false,
                loaded: false,
            };
        default:
            return state;
    }
}

export const reducer = combineReducers({
    testy,
});