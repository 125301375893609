import axios from 'axios';
import { useUserToken } from '../../lib/util/useUserToken';
import { REQUEST, RECEIVE, FAIL } from './action-types';

const transformData = data => {
    const result = data.map(rs => {
        // const toR = {};
        const saleDate = rs.dateCreated;
        const vin = rs.asset.vin;
        const status = rs.status;
        
        const customer = rs.customer ? rs.customer : {};
        const serialNumber = rs.device.serialNumber;
        const firstName = customer.firstName;
        const lastName = customer.lastName;
        const email = customer.email;
        const phone = customer.phone;
        const termLength = "not sure";
        const actions = "check box"

        return [saleDate, vin, status, serialNumber, firstName, lastName, email, phone, termLength, actions];
    });

    return result;
}
function request() {
    return {
        type: REQUEST,
    }
}

function receive(data) {
    const transformedData = transformData(data);
    return {
        type: RECEIVE,
        payload: transformedData,
    }
}

function fail(data) {
    return {
        type: FAIL,
    }
}

function fetchData(token) {
    const headers = {
        'X-Nspire-UserToken': token
    };
    const url = 'https://automotive-services-stage.spireon.com/automotive/sales?productId=goldstar.consumerMobile.transfer&limit=10000&_=1603777282347';

    return dispatch => {
        dispatch(request());
        return axios(
            {
                method: 'get',
                url: url,
                responseType: 'text',
                headers: headers
            }
        )
        .then(response => dispatch(receive(response.data.content)))
        .catch(error => dispatch(fail(error)))
    }
}

export const getData = () => (dispatch) => {
    const token = useUserToken();
    return dispatch(fetchData(token));
}