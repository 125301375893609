import { combineReducers } from 'redux';
import {
    CONNECT_REQUEST,
    CONNECT_RECEIVE,
    CONNECT_FAIL,
    CLEAR_SERIAL_NUMBERS,
    DECODE_VIN_REQUEST,
    DECODE_VIN_REQUEST_RECEIVE,
    DECODE_VIN_REQUEST_FAIL
} from './action-types';

const serialNumbers = (state = {}, action) => {
    switch (action.type) {
        case CLEAR_SERIAL_NUMBERS:
            return {
                ...state,
                data: [],
                error: false,
                loading: false,
                loaded: false,
            };
        case CONNECT_REQUEST:
            return {
                ...state,
                data: [],
                error: false,
                loading: true,
                loaded: false,
            };
        case CONNECT_RECEIVE:
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
                loaded: true,
            };
        case CONNECT_FAIL:
            return {
                ...state,
                data: [],
                error: true,
                loading: false,
                loaded: false,
            };
        default:
            return state;
    }
}

const decodeVin = (state = {}, action) => {
    switch (action.type) {
        case DECODE_VIN_REQUEST:
            return {
                ...state,
                data: [],
                error: false,
                loading: true,
                loaded: false,
            };
        case DECODE_VIN_REQUEST_RECEIVE:
            console.log('IIIIIII', action);
            return {
                ...state,
                data: action.payload,
                error: false,
                loading: false,
                loaded: true,
            };
        case DECODE_VIN_REQUEST_FAIL:
            return {
                ...state,
                data: [],
                error: true,
                loading: false,
                loaded: false,
            };
        default:
            return state;
    }
}
export const reducer = combineReducers({
    serialNumbers,
    decodeVin,
});