import React from 'react';
import PropTypes from 'prop-types';
import MUIDataTable from "mui-datatables";
import { CircularProgress } from '@material-ui/core';
// import { CircularProgress, TableCell } from '@material-ui/core';
// import { Header } from './Header';
import './style.css';

const options = {
    filterType: 'checkbox',
    fixedHeader: false,
    selectableRowsHeader: false,
    selectableRowsHideCheckboxes: true,
    // resizableColumns: true
};
// move to constants file
const columns = [
    {
        name: 'Sales Date',
        options: {
            setCellProps: value => {
                return {
                    style: {
                        // backgroundColor: 'cyan',
                        width: '10%',
                        overflow: 'hidden',
                    }
                }
            },
            setCellHeaderProps: value => {
                return {
                    style: {
                        backgroundColor: '#32383d',
                        color: '#fff',
                        width: '10px',
                    }
                }
            }
        }
    },
    {
        name: 'VIN',
        options: {
            setCellProps: value => {
                return {
                    style: {
                        // backgroundColor: 'yellow',
                        width: '10%',
                        overflow: 'hidden',
                    }
                }
            },
            setCellHeaderProps: value => {
                return {
                    style: {
                        backgroundColor: '#32383d',
                        color: '#fff',
                        width: '10px',
                    }
                }
            }
        }
    },
    {
        name: 'Status',
        options: {
            setCellProps: value => {
                return {
                    style: {
                        // backgroundColor: 'cyan',
                        width: '10%',
                        overflow: 'hidden',
                    }
                }
            },
            setCellHeaderProps: value => {
                return {
                    style: {
                        backgroundColor: '#32383d',
                        color: '#fff',
                        width: '10px',
                    }
                }
            }
        }
    },
    {
        name: 'Serial Number',
        options: {
            setCellProps: value => {
                return {
                    style: {
                        // backgroundColor: 'cyan',
                        width: '10%',
                        overflow: 'hidden',
                    }
                }
            },
            setCellHeaderProps: value => {
                return {
                    style: {
                        backgroundColor: '#32383d',
                        color: '#fff',
                        width: '10px',
                    }
                }
            }
        }
    },
    {
        name: 'First Name',
        options: {
            setCellProps: value => {
                return {
                    style: {
                        // backgroundColor: 'cyan',
                        width: '10%',
                        overflow: 'hidden',
                    }
                }
            },
            setCellHeaderProps: value => {
                return {
                    style: {
                        backgroundColor: '#32383d',
                        color: '#fff',
                        width: '10px',
                    }
                }
            }
        }
    },
    {
        name: 'Last Name',
        options: {
            setCellProps: value => {
                return {
                    style: {
                        // backgroundColor: 'cyan',
                        width: '10%',
                        overflow: 'hidden',
                    }
                }
            },
            setCellHeaderProps: value => {
                return {
                    style: {
                        backgroundColor: '#32383d',
                        color: '#fff',
                        width: '10px',
                    }
                }
            }
        }
    },
    {
        name: 'Email',
        options: {
            setCellProps: value => {
                return {
                    style: {
                        // backgroundColor: 'cyan',
                        width: '10%',
                        overflow: 'hidden',
                    }
                }
            },
            setCellHeaderProps: value => {
                return {
                    style: {
                        backgroundColor: '#32383d',
                        color: '#fff',
                        width: '10px',
                    }
                }
            }
        }
    },
    {
        name: 'Phone',
        options: {
            setCellProps: value => {
                return {
                    style: {
                        // backgroundColor: 'cyan',
                        width: '10%',
                        overflow: 'hidden',
                    }
                }
            },
            setCellHeaderProps: value => {
                return {
                    style: {
                        backgroundColor: '#32383d',
                        color: '#fff',
                        width: '10px',
                    }
                }
            }
        }
    },
    {
        name: 'Term Length',
        options: {
            setCellProps: value => {
                return {
                    style: {
                        // backgroundColor: 'cyan',
                        width: '10%',
                        overflow: 'hidden',
                    }
                }
            },
            setCellHeaderProps: value => {
                return {
                    style: {
                        backgroundColor: '#32383d',
                        color: '#fff',
                        width: '10px',
                    }
                }
            }
        }
    },
    {
        name: 'Actions',
        options: {
            setCellProps: value => {
                return {
                    style: {
                        // backgroundColor: 'cyan',
                        width: '10%',
                        overflow: 'hidden',
                    }
                }
            },
            setCellHeaderProps: value => {
                return {
                    style: {
                        backgroundColor: '#32383d',
                        color: '#fff',
                        width: '10px',
                    }
                }
            }
        }
    }];

export const Content = ({
    //props
    data,
    dataIsLoading,
    dataIsLoaded,
    dataIsError,
}) => (
        <div className="recentSalesTable">
            {!dataIsLoaded && dataIsError && <div>ERROR</div>}
            {dataIsLoading && <div className="loader"> <CircularProgress size={400} /> </div>}
            {dataIsLoaded && <MUIDataTable
                title={"Recent Sales"}
                data={data}
                columns={columns}
                options={options}
            />
            }
        </div>
    );

Content.propTypes = {
    data: PropTypes.array.isRequired,
}

Content.displayName = 'Content';