export function useUserToken() {
    const tokenName = 'X-Nspire-UserToken';
    let token = {};

    if (typeof document !== 'undefined') {
        const cookies = document.cookie.match('(^|;) ?' + tokenName + '=([^;]*)(;|$)');
        if (cookies) {
            token = cookies[2];
        } else {
            throw new Error("No cookie token available!");
        }
    }

    // useEffect(() => {
    //     if (typeof document !== 'undefined') {
    //         const cookies = document.cookie.match('(^|;) ?' + tokenName + '=([^;]*)(;|$)');
    //         if (cookies) {
    //             setToken(cookies[2]);
    //         } else {
    //             throw "No cookie token available!";
    //         }
    //     }
    // }, [setToken]);

    return token;
}

// export const useUserToken = () => {
//     console.log('kdjsl');
//     return '';
// }