import { TextField } from '@material-ui/core';
import PropTypes from 'prop-types';

export const SpTextField = ({
    label,
    input,
    meta: { touched, invalid, error },
    className
}) => (
        <TextField
            label={label}
            error={touched && invalid}
            helperText={touched && error}
            {...input}
            variant="filled"
        />
    )

SpTextField.propTypes = {
    label: PropTypes.string.isRequired,
    input: PropTypes.string.isRequired,
}

// SpTextField.defaultProps = {
//     placeholder: "",
// }

SpTextField.displayName = 'SpTextField';