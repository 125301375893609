export const testyData = state => {
    return state.connectPortal.testy.loaded ? state.connectPortal.testy.data : [];
}

export const testyIsLoaded = state => {
    return state.connectPortal.testy.loaded;
}
export const testyLoading = state => {
    return state.connectPortal.testy.loading;
}

export const testyFailed = state => {
    return state.connectPortal.testy.error;
}