import { getFormValues } from 'redux-form';
import { CONNECT_FORM_NAME } from './constants';

export const serialNumbersData = state => {
    return state.connect.serialNumbers.loaded ? state.connect.serialNumbers.data : [];
}

export const serialNumbersLoading = state => {
    console.log('STATE', state);
    return state.connect.serialNumbers.loading ? state.connect.serialNumbers.loading : false;
}

export const serialNumbersError = state => {
    return state.connect.serialNumbers.error;
}

export const connectFormData = state => {
    console.log(state);
}

export const firstNameField = state => {
    return state.connect.firstNameField;
}

export const decodeVinData = state => {
    return state.connect.decodeVin.loaded ? state.connect.decodeVin.data : {};
}

export const decodeVinLoading = state => {
    return state.connect.decodeVin.loading;
}

export const decodeVinLoaded = state => {
    return state.connect.decodeVin.loaded;
}

export const decodeVinError = state => {
    return state.connect.decodeVin.error;
}

export const connectFormValues = state => {
    return getFormValues(CONNECT_FORM_NAME)(state);
}