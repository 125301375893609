import axios from 'axios';
import { useUserToken } from '../../lib/util/useUserToken';
import {
    CONNECT_REQUEST,
    CONNECT_RECEIVE,
    CONNECT_FAIL,
    CLEAR_SERIAL_NUMBERS,
    DECODE_VIN_REQUEST,
    DECODE_VIN_REQUEST_RECEIVE,
    DECODE_VIN_REQUEST_FAIL
} from './action-types';

// const asBaseUrl = 'https://automotive-services-stage.spireon.com';
const avsBaseUrl = 'https://avs-api-vfm-stage.spireon.com';
// const coreBaseUrl = 'https://platformapi-stage.spireon.com/v0/rest';

export const handleSubmit = () => {
    console.log('submitted');
}

function request() {
    return {
        type: CONNECT_REQUEST,
    }
}

function receive(data) {
    return {
        type: CONNECT_RECEIVE,
        payload: data,
    }
}

function fail(data) {
    return {
        type: CONNECT_FAIL,
    }
}

function fetchData(token) {
    const filter = '[{"property": "batteryDevice", "operator": "neq", "value": [true]}]';
    const max = 25;
    const config = JSON.stringify({ "assetSearchText": "W2", "queryFields": "serialNumber" });


    const headers = {
        'X-Nspire-UserToken': token,
    };
    const encodedURL = encodeURI(`${avsBaseUrl}/vehicles?max=${max}&config=${config}&filter=${filter}`);

    return dispatch => {
        dispatch(request());
        return axios(
            {
                method: 'get',
                url: encodedURL,
                responseType: 'text',
                headers: headers
            }
        )
            .then(response => dispatch(receive(response.data)))
            .catch(error => dispatch(fail(error)))
    }
}

export const getData = () => (dispatch) => {
    const token = useUserToken();
    return dispatch(fetchData(token));
}

export const clearData = () => {
    return {
        type: CLEAR_SERIAL_NUMBERS,
    }
}

const decodeVinRequest = () => {
    return {
        type: DECODE_VIN_REQUEST,
    }
}

function receiveDecodeVin(data) {
    return {
        type: DECODE_VIN_REQUEST_RECEIVE,
        payload: data,
    }
}

function failDecodeVin(data) {
    return {
        type: DECODE_VIN_REQUEST_FAIL,
    }
}

function fetchDecodeVin(token, vin) {
    const baseUrlTemp = 'http://amw1auto1.stage.spireon.com';
    const url = `${baseUrlTemp}/vindecoder/vehicleInfo?vin=${vin}`;
    console.log('URL', url);
    const headers = {
        'X-Nspire-UserToken': token,
    };

    return dispatch => {
        dispatch(decodeVinRequest());
        return axios(
            {
                method: 'get',
                url: url,
                responseType: 'text',
                headers: headers
            }
        )
            .then(response => dispatch(receiveDecodeVin(response.data)))
            .catch(error => dispatch(failDecodeVin(error)))
    }
}

export const decodeVin = () => (dispatch) => {
    const token = useUserToken();
    const vin = '1G4AW69Y7DH525459';
    return dispatch(fetchDecodeVin(token, vin));

}


// const decodeVin = async (vin, token) => {
//     const url = `${asBaseUrl}/vindecoder/vehicleInfo?vin=${vin}`;

//     const headers = {
//         'X-Nspire-UserToken': token
//     };

//     return axios(
//         {
//             headers: headers,
//             method: 'get',
//             url
//         }
//     );
// };