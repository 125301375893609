import { compose } from 'redux';
import { connect } from 'react-redux';
import { Content } from '../components/Content';
import { getData } from '../actions';
import * as selectors from '../selectors';
import lifecycle from 'react-pure-lifecycle';

const mapStateToProps = state => ({
    data: selectors.testyData(state),
    dataIsLoaded: selectors.testyIsLoaded(state),
    dataIsLoading: selectors.testyLoading(state),
    dataIsError: selectors.testyFailed(state),
});

const mapDispatchToProps = {
    getData,
};

const lifecycleMethods = {
    componentDidMount(props) {
        props.getData();
    }
}
const container = compose(
    connect(mapStateToProps, mapDispatchToProps),
    lifecycle(lifecycleMethods),
)(Content);


export { container as Content };