import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CircularProgress from '@material-ui/core/CircularProgress';

export const SpAutoCompleteField = ({
    getOptions,
    clearOptions,
    options,
    isLoading,
    label,
    id,
}) => {
    const minLength = 3;
    const [open, setOpen] = React.useState(false);
    const [value, setValue] = React.useState(options[0]);
    const [inputValue, setInputValue] = React.useState('');

    return (
        <Autocomplete
            noOptionsText={inputValue.length < minLength ? 'Enter at least 3 characters...' : `${inputValue} not found`}
            id={id}
            // freeSolo
            open={open}
            onOpen={() => {
                setOpen(true);
                if (inputValue.length >= minLength) {
                    getOptions();
                }
            }}
            onClose={() => {
                setOpen(false);
            }}
            value={value}
            onChange={(event, newValue) => {
                setValue(newValue);
            }}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
                if (newInputValue.length >= minLength) {
                    getOptions();
                } else {
                    clearOptions();
                }
            }}
            getOptionLabel={(option) => option.id}
            options={options}
            loading={isLoading}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    variant="outlined"
                    InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                            <React.Fragment>
                                {isLoading ? <CircularProgress color="inherit" size={20} /> : null}
                                {params.InputProps.endAdornment}
                            </React.Fragment>
                        ),
                    }}
                />
            )}
        />
    )
}

SpAutoCompleteField.propTypes = {
    getOptions: PropTypes.func.isRequired,
    clearOptions: PropTypes.func.isRequired,
    options: PropTypes.array.isRequired,
    isLoading: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
}

SpAutoCompleteField.displayName = 'SpAutoCompleteField';
