import { compose } from 'redux';
import { connect } from 'react-redux';
import { reduxForm } from 'redux-form';
import { Content } from '../components/Content';
import { handleSubmit, getData, clearData, decodeVin } from '../actions';
import * as selectors from '../selectors';
import { CONNECT_FORM_NAME } from '../constants';

const mapStateToProps = state => ({
    serialNumbers: selectors.serialNumbersData(state),
    serialNumbersLoading: selectors.serialNumbersLoading(state),
    initialValues: {
        make: selectors.decodeVinLoaded(state) ? selectors.decodeVinData(state).make : '',
        model: selectors.decodeVinLoaded(state) ? selectors.decodeVinData(state).model : '',
        year: selectors.decodeVinLoaded(state) ? selectors.decodeVinData(state).year : '',
    },
    enableReinitialize: true,
    decodeVinLoading: selectors.decodeVinLoading(state),
});

const mapDispatchToProps = {
    handleSubmit,
    getData,
    clearData,
    decodeVin,
};

const container = compose(
    connect(mapStateToProps, mapDispatchToProps),
    reduxForm({
        form: CONNECT_FORM_NAME,
    }),
)(Content);

export { container as Content };