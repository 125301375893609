import React from 'react';
import { Grid } from '@material-ui/core';
import { Content as ConnectContent } from './connect-portal/connect/containers/Content'
import { Content as RecentSalesContent } from './connect-portal/recent-sales/containers/Content'
import { LeftDrawer } from './lib/ui/components/LeftDrawer';
import './styles.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";

function App() {
  return (
    <Router>
      <div className="app">
        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
        >
          <Grid item xs={3}>
            <LeftDrawer items={[
              { route: '/', text: 'Home' },
              { route: '/connect', text: 'Connect' },
              { route: '/recent-sales', text: 'Recent Sales' }]} />
          </Grid>
          <Grid item xs={9}>
            <Switch>
              <Route path="/connect">
                <ConnectContent />
              </Route>
              <Route path="/recent-sales">
                <RecentSalesContent />
              </Route>
              <Route path="/">
                HOME
            </Route>
            </Switch>
          </Grid>
        </Grid>
      </div>
    </Router>
  );
}

export default App;
