import { configureStore } from '@reduxjs/toolkit';
import { reducer as testyReducer } from '../connect-portal/recent-sales/reducers';
import { reducer as connectReducer } from '../connect-portal/connect/reducers';
import { reducer as formReducer } from 'redux-form';

export default configureStore({
  reducer: {
    connectPortal: testyReducer,
    connect: connectReducer,
    form: formReducer,
  },
  // formReducer: formReducer,
});
