import React from 'react';
import { Grid, Button } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import PropTypes from 'prop-types';
import { Field } from 'redux-form';
import { SpTextField } from '../../../lib/ui/components/SpTextField';
import { SpAutoCompleteField } from '../../../lib/ui/components/SpAutoCompleteField';
import './styles.css';

export const Content = ({
    decodeVin,
    handleSubmit,
    getData,
    clearData,
    serialNumbers,
    serialNumbersLoading,
    decodeVinLoading,
}) => {
    return (
        <>
            <form onSubmit={handleSubmit} className='connectForm'>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <p className='sectionLabel'>Vehicles</p>
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="serialNumber"
                            component={SpAutoCompleteField}
                            props={{
                                getOptions: getData,
                                clearOptions: clearData,
                                options: serialNumbers,
                                isLoading: serialNumbersLoading,
                                label: 'Serial Number',
                                id: 'serialNumber'
                            }}
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="displayName"
                            component={SpAutoCompleteField}
                            props={{
                                getOptions: getData,
                                clearOptions: clearData,
                                options: serialNumbers,
                                isLoading: serialNumbersLoading,
                                label: 'Display Name',
                                id: 'displayName'
                            }}
                        />
                    </Grid>
                    <Grid item xs={5}>
                        <Field
                            className="vinTextField"
                            name="vin"
                            component={SpTextField}
                            label="VIN"
                        />
                    </Grid>
                    <Grid item xs={1}>
                        <Button className="search-button"
                            id="connect_form_vinSearchButton"
                            onClick={decodeVin}
                            variant="contained">
                            <SearchIcon className="search-icon" />
                        </Button>
                    </Grid>
                    <Grid item xs={6} />
                    <Grid item xs={2}>
                        <Field
                            name="year"
                            component={SpTextField}
                            label="Year"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name="make"
                            component={SpTextField}
                            label="Make"
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Field
                            name="model"
                            component={SpTextField}
                            label="Model"
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <Field
                            name="mileage"
                            component={SpTextField}
                            label="Mileage"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* empty */}
                    </Grid>
                    <Grid item xs={12}>
                        <p className='sectionLabel'>Customer</p>
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="firstName"
                            component={SpTextField}
                            label="First Name"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="lastName"
                            component={SpTextField}
                            label="Last Name"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="email"
                            component={SpTextField}
                            label="Email"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="phone"
                            component={SpTextField}
                            label="Phone"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Field
                            name="address"
                            component={SpTextField}
                            label="Address"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <Field
                            name="city"
                            component={SpTextField}
                            label="City"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Field
                            name="state"
                            component={SpTextField}
                            label="State"
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Field
                            name="postalCode"
                            component={SpTextField}
                            label="Postal Code"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        {/* empty */}
                    </Grid>
                    <Grid item xs={12}>
                        <p className='sectionLabel'>Consumer Term Length</p>
                    </Grid>
                </Grid>
                <Button variant="outlined">Submit</Button>
            </form>
        </>
    );
}

Content.propTypes = {
    decodeVin: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    getData: PropTypes.func.isRequired,
    clearData: PropTypes.func.isRequired,
    serialNumbers: PropTypes.array.isRequired,
    serialNumbersLoading: PropTypes.bool.isRequired,
    decodeVinLoading: PropTypes.bool.isRequired,
}

Content.displayName = 'Content';